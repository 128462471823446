// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keyword-js": () => import("./../../../src/pages/keyword.js" /* webpackChunkName: "component---src-pages-keyword-js" */),
  "component---src-pages-movie-movies-json-slug-js": () => import("./../../../src/pages/movie/{moviesJson.slug}.js" /* webpackChunkName: "component---src-pages-movie-movies-json-slug-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-tv-js": () => import("./../../../src/pages/tv.js" /* webpackChunkName: "component---src-pages-tv-js" */),
  "component---src-pages-tv-shows-json-slug-js": () => import("./../../../src/pages/tv/{showsJson.slug}.js" /* webpackChunkName: "component---src-pages-tv-shows-json-slug-js" */),
  "component---src-templates-company-js": () => import("./../../../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-genre-js": () => import("./../../../src/templates/genre.js" /* webpackChunkName: "component---src-templates-genre-js" */),
  "component---src-templates-genre-tv-js": () => import("./../../../src/templates/genreTV.js" /* webpackChunkName: "component---src-templates-genre-tv-js" */),
  "component---src-templates-keyword-js": () => import("./../../../src/templates/keyword.js" /* webpackChunkName: "component---src-templates-keyword-js" */),
  "component---src-templates-keyword-tv-js": () => import("./../../../src/templates/keywordTV.js" /* webpackChunkName: "component---src-templates-keyword-tv-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */)
}

